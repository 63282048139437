import Vue from 'vue'

export default {
  SET (state, payload) {
    state.fuelSupply = Object.assign({}, state.fuelSupply, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.fuelSupplys = payload
  },
  SET_FUEL_SUPPLYS_GRID (state, payload) {
    state.fuelSupplysGrid = payload
  },
  STORE (state, payload) {
    state.fuelSupplys.push(payload) //precisa ser assim para manter a reatividade
    state.fuelSupplys.sort((a, b) => { return new Date(a.date_of_supply) - new Date(b.date_of_supply) }) //ORDENA POR DATA

    state.fuelSupplysGrid.data.push(payload)
    state.fuelSupplysGrid.data.sort((a, b) => { return new Date(a.date_of_supply) - new Date(b.date_of_supply) }) //ORDENA POR DATA
    state.fuelSupplysGrid.total = state.fuelSupplysGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.fuelSupplys.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.fuelSupplys, idx, payload)

    const idx1 = state.fuelSupplysGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.fuelSupplysGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.fuelSupplys.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.fuelSupplys.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.fuelSupplysGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.fuelSupplysGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.fuelSupplysGrid.total = state.fuelSupplysGrid.total - 1
    }
  }

}
