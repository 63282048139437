export default {
  get (state) {
    const fuelSupply = JSON.parse(JSON.stringify(state.fuelSupply))
    fuelSupply.date_of_supply = fuelSupply.date_of_supply && fuelSupply.date_of_supply.slice(0, 10)
    fuelSupply.exchange_expiration = fuelSupply.exchange_expiration && fuelSupply.exchange_expiration.slice(0, 10)
    fuelSupply.transaction.payday = fuelSupply.transaction.payday && fuelSupply.transaction.payday.slice(0, 10)
    if (fuelSupply.transaction.situation === 'PAID') fuelSupply.transaction.situation = true
    else fuelSupply.transaction.situation = false

    return fuelSupply
  }
}
